.aside-nav-opener {
    position: absolute;
    top: 1.5rem;
    @media (min-width: $screen-md-min) {
        top: 12.5rem;
    }
    right: 3rem;	
	display: block;
	width: 20px;
	height: 40px;
	z-index:20;
	&:after {
	  content: '\2807';
	  font-size: 40px;
	  font-family: Arial;
	  color:#000;
	  z-index:20;
	}
	&:hover {
	   text-decoration: none;
	   &:after {
	      opacity:.8;
	    }
	}
	span {
	display: none;
	}
	
}

.aside-nav {
	padding: 60px 90px;
	position: absolute;
	background: white;
	box-shadow: -4px 7px 10px 0px rgba(102,102,102,1);
	top: 0rem;
	float:right;
	right: 0;
	width: 100%;
	@media (min-width: $screen-md-min) {
    	width: 40%;
	}
	z-index:30;
	transition: all .25s linear;
	transform: translate3d(0,0,0);
	scrollbar-width: none;
	opacity:1;
	&--hidden {
	   opacity:0;
	   z-index:-10;
	}
	
	.close {
	  position: absolute;
	  left: 32px;
	  top: 32px;
	  width: 32px;
	  height: 32px;
	  opacity: 0.6;
	  
	  &:hover {
	      opacity: 1;
	    }
	    &:before, &:after {
	      position: absolute;
	      left: 15px;
	      content: ' ';
	      height: 33px;
	      width: 2px;
	      background-color: #000;
	    }
	    &:before {
	      transform: rotate(45deg);
	    }
	    &:after {
	      transform: rotate(-45deg);
	    }
	}
	
    a {
        color: #000;
        &:hover {
            text-decoration: none;
            color: $link-color;
        }
    }
    
    h2 {
        font-weight: bold;
        color: #000;
        font-size: 1.5em;
    }
    h3 {
        font-weight: bold;
        color: #000;
        font-size: 1.25em;
    }
    span.num {
        display: inline-block;
        margin-right: 10px;
        font-size: 1.75em;
        font-weight: bold;
    }
    
    ul {
        list-style-type: none;
        margin-bottom: 2*$spacing;
        padding: 0;
        li {
            margin-bottom: .5*$spacing;
        }
        &.main_actions {
            a {
               display:block;
               position: relative;
               padding-left: 25px;
               line-height: 130%;
               margin-bottom: 8px;
               i {
                   position: absolute;
                   left: 5px;
                   top: 2px;
                   text-align: center;
                   width: 20px;
               }
           }
        }
        &.bottom-bordered {
            li {
                border-bottom: 1px solid #969696;
                padding-bottom: .5*$spacing;
            }
        }
        &.bold {
            font-weight: bold;
        }
        &.inline-list {
            li {
                display: inline-block;
                padding-right: 16px;
                position: relative;
                &:after {
	                position: absolute;
					content: '';
					background: $link-color;
					width: 2px;
					height: 15px;
					display: block;
					right: 5px;
					top: 3px;
                }
                &:last-child {
                    padding-right: 0;
                    &:after {
                        display: none;
                    }
                }
                a {
                    color: $link-color;
                    &:hover {
                    text-decoration: underline;
                    }
                }
                
            }
        }
    }

    
}