.cookies {
    background-color: $brand-primary;
    background-color: rgba($brand-primary,.9);
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 10;
    padding-top: 1rem;
    padding-bottom: 1rem;

    p {
        color: white;
        margin: 0;
    }

    a {
        color: white;
        text-decoration: underline;
    }

    .btn {
        margin-left: .5em;
        margin-right: .5em;
    }
}
