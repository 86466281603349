/***
Browse columns
***/

.browse-panes:after {
    content: "";
    display: block;
    clear: both
}

.browse-panes {
    // padding-left: 15px;
    // padding-right: 15px;
    // padding-bottom: 30px;
    position: relative;
}

@media (min-width: 641px) {
    .browse-panes {
        // padding-left: 30px;
        // padding-right: 30px
    }
}

@media (min-width: 641px) {
    .browse-panes {
        // padding-top: 30px
    }
}

.browse-panes.section #root {
    display: none
}

// @media (min-width: 641px) {
//     .browse-panes.section #root {
//         display: block;
//         float: right;
//         width: 25%
//     }
// }

// @media (min-width: 641px) {
//     .browse-panes.section #section {
//         float: right;
//         width: 30%;
//         margin-right: 45%
//     }
// }

// @media (min-width: 769px) {
//     .browse-panes.section #section {
//         width: 35%;
//         margin-right: 40%
//     }
// }

// .browse-panes.subsection #root,
// .browse-panes.subsection #section {
//     display: none;
//     color: #6f777b
// }

// @media (min-width: 641px) {
//     .browse-panes.subsection #root,
//     .browse-panes.subsection #section {
//         display: block;
//         float: right;
//         width: 25%
//     }
// }

// .browse-panes.subsection #root a,
// .browse-panes.subsection #root p,
// .browse-panes.subsection #section a,
// .browse-panes.subsection #section p
.browse-panes .pane p,
.browse-panes .pane a {
    color: #6f777b
}

// @media (min-width: 641px) {
//     .browse-panes.subsection #section {
//         margin-left: -18%;
//         width: 30%
//     }
// }

// @media (min-width: 769px) {
//     .browse-panes.subsection #section {
//         margin-left: -13%;
//         width: 25%
//     }
// }

// @media (min-width: 641px) {
//     .browse-panes.subsection #subsection {
//         float: right;
//         width: 50%;
//         margin-right: 13%
//     }
// }

.browse-panes .pane-inner {
    padding-bottom: 30px
}

@media (min-width: 641px) {
    .browse-panes .pane-inner {
        border-left: 1px solid #bfc1c3;
        min-height: 950px
    }
}

@media (min-width: 769px) {
    .browse-panes .pane-inner {
        min-height: 700px
    }
}

.browse-panes h1,
.browse-panes h2 {
    // font-family: "nta", Arial, sans-serif;
    font-size: 20px;
    line-height: 1.11111;
    font-weight: 700;
    text-transform: none;
    margin-bottom: 10px;
    margin-top: 10px;
    outline: none;
}

@media (min-width: 641px) {
    .browse-panes h1,
    .browse-panes h2 {
        font-size: rem-calc(16);
        line-height: 1.11111
    }
}

@media (min-width: 641px) {
    .browse-panes h1,
    .browse-panes h2 {
        margin-top: 0;
        margin-bottom: 100px
    }
}

// .browse-panes #root,
// .browse-panes #section
.browse-panes .pane {
    min-height: 20px
}

@media (min-width: 641px) {
    // .browse-panes #root h1,
    // .browse-panes #root h2,
    // .browse-panes #section h1,
    // .browse-panes #section h2
    .browse-panes .pane h1,
    .browse-panes .pane h2 {
        padding-left: 15px
    }
}

// .browse-panes #root .sort-order,
// .browse-panes #section .sort-order {
//     display: none
// }

// .browse-panes #root ul li,
// .browse-panes #section ul li
.browse-panes .pane ul li {
    list-style: none;
    position: relative;
    .new {
       position: absolute;
       top: 5px;
       left: 6px;
    }
}
.browse-panes .pane ul.documents li a {
    .starburst { 
        width:15px;
        height:15px;
        position: absolute;
        top: 7px;
        left: 7px;
        span {
            width:15px;
            height:15px;
        }
    }
    .new {
       display: inline-block;
       margin-left: 40px;
       font-size: 12px;
       color: #333;
       position: relative;
       top:0;
       left:0;
       .starburst { 
            width:15px;
            height:15px;
            position: absolute;
            top: 0px;
            left: -20px;
            span {
                width:15px;
                height:15px;
            }
        }
    }
}

// .browse-panes #root ul a,
// .browse-panes #section ul a
.browse-panes .pane ul a {
    position: relative;
    display: block;
    // font-family: "nta", Arial, sans-serif;
    // font-size: 16px;
    line-height: 1.25;
    // font-weight: 400;
    // text-transform: none;
    text-decoration: none;
    padding: 12px 25px 8px 10px;
}

@media (min-width: 641px) {
    // .browse-panes #root ul a,
    // .browse-panes #section ul a
    .browse-panes .pane ul a {
        font-size: 19px;
        line-height: 1.31579
    }
}

@media (min-width: 641px) {
    // .browse-panes #root ul a,
    // .browse-panes #section ul a
    .browse-panes .pane ul a {
        padding: 12px 30px 8px 15px;
        // font-family: "nta", Arial, sans-serif;
        font-size: 14px;
        line-height: 1.14286;
        // font-weight: 400;
        // text-transform: none
    }
}

@media (min-width: 641px) and (min-width: 641px) {
    // .browse-panes #root ul a,
    // .browse-panes #section ul a
    .browse-panes .pane ul a {
        font-size: 16px;
        line-height: 1.25
    }
}

// .browse-panes #root ul a:hover,
// .browse-panes #section ul a:hover
.browse-panes .pane ul a:hover {
    background: #dee0e2;
    color: $brand-main-color
}

// .browse-panes #root ul a:after,
// .browse-panes #section ul a:after,
.browse-panes .pane ul a:after {
    position: absolute;
    top: 50%;
    margin-top: -8px;
    right: 10px;
    float: right;
    // content: "\203A"
}


// .browse-panes #root ul a.loading:after,
// .browse-panes #section ul a.loading:after,
.browse-panes .pane ul a.loading:after {
    content: ""
}

// .browse-panes #root ul a h3,
// .browse-panes #section ul a h3,
.browse-panes .pane ul a h3 {
    // font-weight: bold
}

// .browse-panes #root ul a p,
// .browse-panes #section ul a p,
.browse-panes .pane ul a p {
    // color: #0b0c0c;
    // font-family: "nta", Arial, sans-serif;
    font-size: 12px;
    line-height: 1.25;
    // font-weight: 400;
    text-transform: none
}

@media (min-width: 641px) {
    // .browse-panes #root ul a p,
    // .browse-panes #section ul a p,
    .browse-panes .pane ul a p {
        font-size: 14px;
        line-height: 1.42857
    }
}

// .browse-panes #root ul li.active a,
// .browse-panes #section ul li.active a,
.browse-panes .pane ul li.active a {
    background: $brand-main-color;
    color: #fff
}

// .browse-panes #root ul li.active a:hover,
// .browse-panes #section ul li.active a:hover,
.browse-panes .pane ul li.active a:hover {
    background: $brand-main-color
}

// .browse-panes #root ul li.active a p,
// .browse-panes #section ul li.active a p,
.browse-panes .pane ul li.active a p {
    color: #fff
}

// .browse-panes #section {
//     z-index: 2;
//     background: #fff;
//     position: relative
// }

// @media (min-width: 769px) {
//     .browse-panes #section.with-sort .pane-inner {
//         padding-left: 30px
//     }
//     .browse-panes #section.with-sort .pane-inner.alphabetical {
//         padding-left: 96px
//     }
//     .browse-panes #section.with-sort .sort-order {
//         // font-family: "nta", Arial, sans-serif;
//         font-size: 20px;
//         line-height: 1.11111;
//         font-weight: 700;
//         text-transform: none;
//         float: left;
//         display: block;
//         width: 75px;
//         margin-left: -90px;
//         padding: 11px 0 15px 15px
//     }
// }

// @media (min-width: 769px) and (min-width: 641px) {
//     .browse-panes #section.with-sort .sort-order {
//         font-size: 27px;
//         line-height: 1.11111
//     }
// }

// @media (min-width: 641px) {
//     .browse-panes #subsection .pane-inner.a-to-z {
//         padding-left: 100px
//     }
//     .browse-panes #subsection .pane-inner.curated-list {
//         padding-left: 30px
//     }
// }

// .browse-panes #subsection h1 {
//     padding-left: 0
// }

// .browse-panes #subsection .list-header {
//     // font-family: "nta", Arial, sans-serif;
//     font-size: 16px;
//     line-height: 1.25;
//     font-weight: 700;
//     text-transform: none;
//     margin: 30px 0 0 0
// }

// @media (min-width: 641px) {
//     .browse-panes #subsection .list-header {
//         font-size: 19px;
//         line-height: 1.31579
//     }
// }

// .browse-panes #subsection .curated-list .list-header {
//     margin: 30px 0 5px 0
// }

// .browse-panes #subsection .sort-order {
//     display: none
// }

// @media (min-width: 641px) {
//     .browse-panes #subsection .sort-order {
//         display: block;
//         // font-family: "nta", Arial, sans-serif;
//         font-size: 20px;
//         line-height: 1.11111;
//         font-weight: 700;
//         text-transform: none;
//         float: left;
//         width: 75px;
//         margin-left: -100px;
//         padding: 11px 0 15px 15px
//     }
// }

// @media (min-width: 641px) and (min-width: 641px) {
//     .browse-panes #subsection .sort-order {
//         font-size: 27px;
//         line-height: 1.11111
//     }
// }

// .browse-panes #subsection ul {
//     padding: 0;
//     list-style: none
// }

// .browse-panes #subsection ul li {
//     padding: 0;
//     margin: 0
// }

// .browse-panes #subsection ul li a {
//     // font-family: "nta", Arial, sans-serif;
//     font-size: 16px;
//     line-height: 1.25;
//     font-weight: 700;
//     text-transform: none;
//     display: block;
//     text-decoration: none;
//     padding: 10px 30px 10px 0
// }

// @media (min-width: 641px) {
//     .browse-panes #subsection ul li a {
//         font-size: 19px;
//         line-height: 1.31579
//     }
// }

// .browse-panes #subsection .curated-list ul li a {
//     padding: 5px 30px 5px 0
// }

// .browse-panes #subsection .detailed-guidance {
//     margin-top: 30px
// }


// customització
.browse-panes {
    .pane {
      ul {

        li {
          margin-bottom: 0;
        }

        a {
          letter-spacing: normal;
          color: $general-text-color;
        }
        &.documents a{
            color: $brand-primary;
        } 
        &#root {
           a {
               font-weight: bold;
               font-size: 14px !important;
           }
        }
      }
    }

    h1 {
      color: black;
      text-transform: uppercase;
      // font-size: rem-calc(12);
      text-align: left;
      letter-spacing: 1.9px;
      // color: $general-text-color;
      overflow: hidden;
    }
    h3 {
      text-transform:none;
      letter-spacing: normal;
      position:relative;
      
      font-size: 1em;
      padding: 0;
      line-height: 160%;
      margin-top: -5px;
      margin-bottom: 0;
      
      &:after{
         position: absolute;
         right: -15px;
         top: 3px;        
         content:"";
         width:0;
         height:0;
         border-top:7px solid transparent;
         border-bottom:7px solid transparent;
         border-left:7px solid white;      
      }  
      &:hover{
        &:after{
              position: absolute;
              right: -15px;
              top: 3px;        
              content:"";
              width:0;
              height:0;
              border-top:7px solid transparent;
              border-bottom:7px solid transparent;
              border-left:7px solid $brand-main-color;      
           }  
      }    
    }
    .inner-subsection {
        i {float:left;}
        h3 {
            margin-left: 25px;
            margin-top: -5px;
        }
    }
}
#section ul a h3 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: rem-calc(12) !important;
    letter-spacing: 1.5px;
}
.browse-panes #root ul a p,
.browse-panes #section ul a p {
  letter-spacing: 0;
  color: $general-text-color;
}
.browse-panes #subsection ul li a {
  padding-left: 15px;

  &:hover {
    text-decoration: underline;
  }
}
.browse-panes #subsection {
  a {
    color: $brand-main-color;
  }
  h1 {
    padding-left: 15px;
  }
}
.browse-panes #root ul a:after,
.browse-panes #section ul a:after {
    // @include triangle(.5em, white, left);
    
        position: absolute;
    right: -15px;
    top: 3px;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    content: "";
    width: 0;
    height: 0;
    
    margin-top: -6px;
}




// subnivells
.browse-panes {
    // border: 2px solid black;
}
.pane {
    // border: 1px solid red;
    background: white !important;
    // transition: width .2s ease-in;
    
    ul {
        padding: 0;
        margin-left: 0;
    }
}

#root {
     .step2 &,
     .step3 &,
     .step4 &,
     .step5 & {
         display:none;
     }
}
#section1 {
     .step3 &,
     .step4 &,
     .step5 & {
         display:none;
     }
}
#section2 {
     .step4 &,
     .step5 & {
         display:none;
     }
}
#section3 {
     .step5 & {
         display:none;
     }
}
@media (min-width: $screen-sm-min) {
    .browse-panes {
       &.step0 .pane,
       &.step1 .pane,
       &.step2 .pane,
       &.step3 .pane,
       &.step4 .pane,
       &.step5 .pane {
             width: 25%;
             display:block;       
       }       
    }

    #root {
    .step2 &,
    .step3 &,
    .step4 &,
    .step5 & {
        display: block;
    }
    }
    #section1 {
         .step0 & {
             display: none;
         }
         .step1 & {
             width: 50%;
             display: block;
             float: right;
             margin-right: 25%;
         } 
         .step2 & {
             float: right;
             display: block;
             margin-left: -10%;
             z-index: 2;
             position: relative;
         }
         .step3 & {
             float: right;
             display: block;
             margin-left: -10%;
             z-index: 2;
             position: relative;
         } 
         .step4 & {
             float: right;
             display: block;
             margin-left: -15%;
             z-index: 2;
             position: relative;
         } 
         .step5 & {
             float: right;
             display: block;
             margin-left: -20%;
             z-index: 2;
             position: relative;
         }
    }
    #section2 {
         .step0 &,
         .step1 & {
             display: none;
         }
         .step2 & {
             width: 50%;
             float: right;
             margin-right: 10%;
         } 
         .step3 & {
             float: right;
             margin-left: -10%;
             z-index: 3;
             position: relative;
         } 
         .step4 & {
             float: right;
             display: block;
             margin-left: -15%;
             z-index: 3;
             position: relative;
         } 
         .step5 & {
             float: right;
             display: block;
             margin-left: -15%;
             z-index: 3;
             position: relative;
         }
    }
    #section3 {
         .step0 &,
         .step1 &,
         .step2 & {
             display: none;
         }
         .step3 & {
             width: 40%;
             float: right;
             margin-right: 10%;
         } 
         .step4 & {
             float: right;
             margin-left: -10%;
             z-index: 4;
             position: relative;
         } 
         .step5 & {
             float: right;
             display: block;
             margin-left: -10%;
             z-index: 4;
             position: relative;
         } 
    }
    #section4 {
         .step0 &,
         .step1 &,
         .step2 & ,
         .step3 & {
             display: none;
         }
         .step4 & {
             width: 40%;
             float: right;
             margin-right: 0;
         }
         .step5 & {
             float: right;
             margin-left: -10%;
             z-index: 5;
             position: relative;
         } 
    }
    #section5 {
         // border-color: aquamarine;
         .step0 &,
         .step1 &,
         .step2 & ,
         .step3 & ,
         .step4 & {
             display: none;
         }
         .step5 & {
             width: 40%;
             float: right;
             margin-right: 0;
             z-index: 6;
         }
    }
    #subsection {
         // border-color: maroon;
         .step0 &,
         .step1 &,
         .step2 & ,
         .step3 &,
         .step4 & {
             display: none;
         }
         .step5 & {
             width: 50%;
             display: block;
             float: right;
             margin-right: 0;
             z-index: 6;
             position: relative;
         } 
    }
}
.subsection {
    text-transform: none !important;
}



.browse-panes-list ol.categories-list {
    padding-left: 0;
    li {
         list-style: outside none none;
         
         @media (min-width: $screen-sm-min) {
             padding: 0 0 15px;
             margin: 0;
             display:inline-block;
             float:left;
             width: 30%;
             min-height: 160px;
             margin-right: 1rem;
         }
             
         h3 {
             font-size: 19px;
             line-height: 1.31579;
             text-transform: none !important;
         }
    }
}

@media (min-width: $screen-md-min) { /* 992px */

    .page-inner-content-wrapper--narrow {
        margin: 0 95px;
    }
}


/** Cercador documents  **/

.search-form-inner {
    margin-bottom: 3rem;
    .form-control {
	    border-radius: 3px !important;
	    background: #f5f5f5;
	    border: 0;
	    padding-right: 45px;
	}
	.btn:hover {
	   color: #ebebeb;
	}
}

/** Resultats cerca documents  **/

.list.cercador article {
	border-bottom: 1px solid #ccc;
	margin-bottom: 15px;
	padding-bottom: 15px;
	
	.cerca-categoria-element {
	   margin-bottom: 5px;
	   font-size: 13px;
	}
	.cerca-titol-element {
	   margin-top: 0;
	   margin-bottom: 15px;
	   font-size: 20px;
	   font-weight: bold;
	}
	
}

