fieldset {
    margin-bottom: $spacing;
}
legend {
    font-size: $font-size-base;
    color: $legend-color;
    border: 1px solid $gray-dark;
    background-color: $gray-lighter;
    padding: .4em .5em .35em;
    text-transform: uppercase;
    margin-bottom: 10px;
}
@keyframes spinner {
  to {transform: rotate(360deg);}
}

@keyframes loading {
  0% {
    transform-origin: 100% 100%;
    transform: rotate(0deg);
  }
  100% {
    transform-origin: 100% 100%;
    transform: rotate(360deg);
  }
}
        
.form-control {
    box-shadow: none;
    font-size: 16px; // https://github.com/twbs/bootstrap/issues/2159
    @media (min-width: $screen-sm-min) {
        font-size: 12px;
    }
    .row-narrow & {
        padding-left: 4px;
        padding-right: 3px;
    }
    &.datepicker {
/*         padding: $padding-base-vertical $padding-base-horizontal; */
        border-radius: $input-border-radius;
    }
    
    &.validating {
	    border: 2px solid #0275d8 !important;
	    background-color: #ebf3fb;
	}
	
	&.loading {
		background-image: url("img/loading.gif");
	    background-size: 16px 16px;
	    background-position:right center;
	    background-repeat: no-repeat;
	}
	
 	&.needAtenttion { 
         border: 2px solid #f46c03 !important; 
         background-color: #f3d0b5; 
     } 
	
	&.error {
        border: 2px solid red;
        .noShowError & {
        	border: 1px solid #444;
        }
    }
}
label {
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 1px;
    &.error {
        color: red;
        
        .cheboxk & {
            display: block;
        }
        & + label.error {
        	display: none;
        }
        
        .noShowError & {
        	display: none;
        }
    }
    .conditions & {
        font-size: 14px;
        margin-bottom: 20px;
    }
}
.input-group-addon {
    font-size: 12px;
}
button,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
textarea,
select {
    appearance: none;
}

input.dniDonant {
	text-transform: uppercase;
}

.select {
    position: relative;
    select {
        background: transparent;
        padding-right: 20px;
        padding-top: 0;
        padding-bottom: 0;
        @media (min-width: $screen-sm-min) {
            padding-top: 7px;
            padding-bottom: 5px;
        }
    }
    &:after {
        font-family: FontAwesome;
        content: "\f0d7";
        display: block;
        position: absolute;
        right: 10px;
        bottom: 6px;
        z-index: -1;
    }
    &.error {
        &:after {
            top: 30px;
        }
    }
}

// inline forms
.form-inline {
    input[type="checkbox"],
    input[type="radio"] {
        margin-right: $spacing*.5;
    }
    @media (min-width: $screen-sm-min) {
        .form-group,
        .checkbox,
        .radio {
            margin-right: $spacing;
            margin-bottom: 8px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}


.form-group {
    margin-bottom: 8px;
	.select2-container {
	   width: 100% !important;
	}
	.select2-container--default {
		.select2-selection--multiple {
		   border: 1px solid #444;
	       border-radius: 2px;
	       min-height: 34px;
		}
		.select2-search__field {
		  width: 100% !important;
		}
		
		.select2-selection--single {
		  border: 1px solid #444;
		  border-radius: 2px;
		  height: 34px;
          padding: 3px 0px;
          font-size: 12px;
          .select2-selection__arrow {
            top: 4px;
          }
		}
		
		&.select2-container--disabled {
		  span.select2-selection__arrow {
		  display: none;
		  }
		}
	}
	

}
.checkbox, .radio {
    margin-bottom: 5px;
}
.mt-inline {
	.checkbox {
	   margin-top: 24px;
	}
}

#campsCheckboxExport {
	margin-bottom: 10px;
	.checkbox {
	   margin-top:0;
	   margin-bottom: 3px;
	}
}
