.main-header {
    color: #fff;
    background-color: $gray-dark;
    &:after {
        content: '';
        display: block;
        height: $spacing*2;
        background-image: linear-gradient(to right, #f56b02, #00c0d3 50%, #6dc34b);
    }
    .sr-only-focusable:focus {
        position: absolute;
    }
}
.skip-link {
    background-color: #fff;
    padding: $spacing*.5;
    z-index: 1;
}
.site-title {
    text-align: center;
/*     margin: $spacing*1.5 auto $spacing*2; */
    margin: $spacing*1.5 auto 0;
    font-size: 1em;
    position: relative;
/*     width: 215px; */
    height: 79px;
    overflow: hidden;
/*     left: -26px; */
    @media (min-width: $screen-sm-min) {
        width: 253px;
        height: 58px;
        left: auto;
        margin-top: $spacing*2; 
        margin-left: 0;
        margin-right: 0;
        text-align: left;
        margin-bottom: $spacing;
        
    }
    @media (min-width: $screen-sm-min) {
        float: left;
        // margin-bottom: $spacing*2.75;
        margin-right: $spacing*2;
    }
    @media (min-width: $screen-lg-min) {
        /*width: 600px;
        height: 40px;*/
        width: 349px;
        height: 80px;
        margin-top: $spacing;
    }
/*    
    @media (min-width: $screen-md-min) {
        height: auto;
        width: auto;
        img.logo {
           height: 36px;
        }
    }     
*/    
    a,span {
        display: block;
        color: #fff;
        &:before {
            content: '';
            background-image: url('img/logo-redmo-2019-mobil@1x.png');
            background-color: $gray-dark;
            background-repeat: no-repeat;
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            @media (min-width: $screen-sm-min) {
                background-image: url('img/logo-redmo-2019@1x.png');
            }
            @media (min-width: $screen-lg-min) {
                background-image: url('img/logo-redmo-2019@1x.png');
                background-size: 100%;
            }
        }
        .svg & {
            &:before {
                background-image: url('img/logo-redmo-2019-mobil.svg');       
                @media (min-width: $screen-sm-min) {
                    background-image: url('img/logo-redmo-2019.svg');
                }
                @media (min-width: $screen-lg-min) {
                    background-image: url('img/logo-redmo-2019.svg');
                    background-size: 100%;
                }
            }
        }
    }
    
   
}


.login h1.site-title {
	
	margin: $spacing*1.5 auto $spacing*2;
	
	@media (min-width: $screen-sm-min) {
	    height: auto;
        width: auto;
		img.logo {
		   height: 60px; /*40px;*/
	    }
	}
	
	@media (min-width: $screen-md-min) {
        img.logo {
           height: 90px; /*54px;*/
        }
    }
}


.section {
    font-size: 20px;
    text-align: center;
    font-weight: normal;
    line-height: 1.1;
    @media (min-width: $screen-sm-min) {
        margin: 0 0 $spacing 0;
        text-align: left;
        float: left;
    }
    @media (min-width: $screen-md-min) {
        float: left;
        margin-top: $spacing*2.75;
    }
}

.page-head {
	display: block;
	background-color: #fff;
	position: relative;
	.section {
	   background: #f2f2f2;
	   color: #000;
	   font-size: 22px;
	   float: none;
	   text-align: center;
	   margin: 0;
	   padding: 0;
	   font-weight: bold;
	   padding: 30px 0;
	   margin-top: 60px;
	   margin-bottom: $spacing*2;
	   position: relative;
	   &:before {
	       position: absolute;
           content: '';
           display: block;
           top: -45px;
           left: 50%;
           transform:scale(90%);
	   }
	   &.section-cordo {
		   &:before {
              margin-left: -1.59375em;
	          @include sprite('ico-cordon');
	       }
	   }
	   
	   &.section-medula {
           &:before {
              margin-left: -0.4375em;
              @include sprite('ico-medula');
           }
       }
       
       &.section-pacients {
           &:before {
              margin-left: -2.25em;
              @include sprite('ico-pacients');
           }
       }
       
       &.section-docs {
           &:before {
              margin-left: -1.125em;
              @include sprite('ico-doc');
           }       
       }
       
       &.section-cerques {
           &:before {
              margin-left: -1.5em;
              @include sprite('ico-lupa');
           }         
       }
       &.section-contacte {
           &:before {
              margin-left: -1.8125em;
              @include sprite('ico-directori');
           }         
       }
       
       &.section-config {
           &:before {
              margin-left: -1.6875em;
              @include sprite('ico-settings');
           }         
       }
       
       &.section-wiki {
           &:before {
              margin-left: -1.125em;
              @include sprite('ico-doc');
           }       
       }
       
       &.section-coordinacioautonomica {
           &:before {
              top: -50px;
              margin-left: -3.125em;
              @include sprite('ico-coordinacio_autonomica');
           }       
       }
	}
}

.sections-menu {
	
	display:none;
	
	@media (min-width: $screen-md-min) {
	
	    display:block;
		list-style-type:none;
		text-align:center;
		padding:0;
		margin: 0 0 20px;
		
		li {
		   display: inline;
	       padding: 0;	   
		   margin: 0;
		   a {
	    	   position: relative;
		       display:inline-block;
		       padding: 15px 20px;
		       color: #000;
		       &:after {
	               position: absolute;
	               content: '';
	               background: #c0c0c0;
	               width: 2px;
	               height: 35px;
	               display:block;
	               right: -3px;
	               top: 8px;
	           }
		       &:hover {
		           background-color:#000;
		           color:#fff;
		           text-decoration: none;
		       }
		       
		       .new {
		            position: absolute;
		            top: 12px;
		            right: 7px;
		       }
		   }
		   &.active {
			   a {
			       background-color:#000;
		           color:#fff;
		           font-weight: bold;
			   }
	       }
	       
	       &:last-child {
	            a:after {
	                display: none;
	            } 
	       }
	       
		   
		}
	}
}