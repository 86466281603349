
.vcard {
	margin-bottom: 2rem;
	.fn.org {
	   font-size: 18px;
	   display:block;
	   margin-bottom: .75rem;
	   font-weight: bold;
	}
	.h-adr {
	   margin-bottom: 15px;
	}
	div {
	   margin-bottom: .75rem
	}
	span.type { font-weight: bold; display: inline-block; margin-right: 1rem; }

}

