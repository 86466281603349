table {
    font-size: 12px;
}

// https://stackoverflow.com/questions/17408815/fieldset-resizes-wrong-appears-to-have-unremovable-min-width-min-content/17863685#17863685
@-moz-document url-prefix() {
    fieldset { display: table-cell; }
}

.row-narrow .table td {
	vertical-align: middle;
	padding: 0 .5em;
}