.usr-menu {
    margin: 0 auto $spacing*1.5;
    position: relative;
    width: 100%;
    text-align: center;
    
    button {
        background-color: transparent;
        border: 0;
        width: 100%;
        float: none;
        @media (min-width: $screen-sm-min) {
            width: auto;
        }
        &:hover,
        &:active,
        &:focus {
            background-color: transparent;
            border: 0;
            box-shadow: none !important;
        }
    }
    .dropdown-menu {
        width: 100%;
        @media (min-width: $screen-sm-min) {
            left: auto;
            right: 0;
            width: auto;
        }
    }
}
.header-nav {
	@media (min-width: $screen-sm-min) {
        width: auto;
        float: right;
    }
    @media (min-width: $screen-sm-min) {
/*         margin-top: $spacing*2.75; */
        margin-top: $spacing*1.75;
    }
    @media (min-width: $screen-lg-min) {
/*         margin-top: $spacing*3.75; */
        margin-top: $spacing*1.25;
    }
    
    .secondary-nav {
        font-size: 11px;
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            text-align:center;
            @media (min-width: $screen-lg-min) {
                text-align:right;
            }
            li {
                display: inline-block;
                padding: 0 1rem;
                
                &.active {
                    a {
                        border-bottom: 2px solid #fff;
                    }
                }
            }
        }
        a {
            color:#fff;
            display: block;
            padding-top: 30px;
            position: relative;
            border-bottom: 2px solid transparent;
            &:before {
                font-size: 8px;
            }
            &:hover {
                color:#ababab;
                text-decoration: none;
            }
            &.contacte {
	            &:before {
		          content: '';
		          display: block;
		          position: absolute;
		          top: 0;
		          left: 50%; 
		          margin-left: -1.5em;
		          @include sprite('ico-directori');
		       }
            }
        }
    }
}