/***
Llistat d'adjunts
***/

.attached-list {
	margin-top: $base-spacing;
	margin-bottom: $base-spacing;
	border: 1px solid lightgray;
	list-style-type:none;
}

.attached-list__item {
	margin: 0 !important;
	padding: $base-spacing $base-spacing $base-spacing 75px !important;
	border-bottom: 1px solid lightgray;
	&:before {
    display: none !important;
	}
	&.last {
		border-bottom: 0;
	}
	&--file {
		background: url('../img/icon_download.png') no-repeat 0 $base-spacing;
	}
	&--link {
		background: url('../img/link.png') no-repeat $base-spacing $base-spacing;
	}
	
	a {
	   text-decoration: none;
	}
}
.attachedDocument a {
	display:block;
	text-decoration: none;
    margin: 2em 0 2em !important;
    padding: $base-spacing $base-spacing $base-spacing 115px !important;
    border: 1px solid lightgray;
    background: url('../img/icon_download.png') no-repeat 40px $base-spacing;
    position: relative;
	
	&:hover {
	   box-shadow:         2px 2px 2px 1px #ccc;
	}
	
	.ribbon {
	  position: absolute;
	  right: -5px; top: -5px;
	  z-index: 1;
	  overflow: hidden;
	  width: 75px; height: 75px;
	  text-align: right;
	}
	.ribbon span {
	  font-size: 10px;
	  font-weight: bold;
	  color: #FFF;
	  text-transform: uppercase;
	  text-align: center;
	  line-height: 20px;
	  transform: rotate(45deg);
	  -webkit-transform: rotate(45deg);
	  width: 100px;
	  display: block;
	  background: #79A70A;
	  background: linear-gradient(#F56B06 0%, #F56B06 100%);
	  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
	  position: absolute;
	  top: 19px; right: -21px;
	}
	.ribbon span::before {
	  content: "";
	  position: absolute; left: 0px; top: 100%;
	  z-index: -1;
	  border-left: 3px solid #F56B06;
	  border-right: 3px solid transparent;
	  border-bottom: 3px solid transparent;
	  border-top: 3px solid #F56B06;
	}
	.ribbon span::after {
	  content: "";
	  position: absolute; right: 0px; top: 100%;
	  z-index: -1;
	  border-left: 3px solid transparent;
	  border-right: 3px solid #F56B06;
	  border-bottom: 3px solid transparent;
	  border-top: 3px solid #F56B06;
	}
}
.attached-list__title {
	font-weight: bold;
	font-size: 20px;
	margin: 0 0 $base-spacing*.25 !important;
	text-transform: none;
	
	small {
	   display:block;
	   padding-top: .5em;
	   padding-bottom: .5em;
	}
	.secondary & {
	   font-size: 16px;
	}
}

.attached-list__descr {
	font-weight: normal;
	font-size: 14px;
	margin: 0 0 $base-spacing*.25 !important;

	.aside-block & {
		font-size: 12px;
	}
}

.attached-list__link {
	font-weight: bold;
	font-size: 14px;
	margin: 0;

}

.relatedDocuments {
	h2 {
	    font-size: 24px;
	    font-weight: 700;
	    margin-top: 2em;
	}
	table {
	   font-size: 1em;
	}	
}

.fitxes-list {
	list-style-type: none;
	padding: 0;
	marging: 0 0 2em;
	
	&:after {
	   content: '';
       display: block;
	   clear: both;
	}
	
	li {
	   border-bottom: 1px solid #bdbdbd;
	   margin-bottom: .75rem !important;
       padding-bottom: .75rem;
       
        @media (min-width: $screen-md-min) {
            float: left;
            width: 30%;
            margin-right: 3%;
            &:nth-child(3n+1){
			    clear:left
			}
        }
	}
	a:hover {
	   text-decoration: none;
	}
	&__title {
    	font-style: normal;
		font-weight: 400;
		font-size: 2rem;
		text-transform: none;
		margin-bottom: .375rem !important;
		
		small {
		  display: block;
		  padding-top: .5em;
		}
	}
	&__categoria {
	   margin-top: .75em;
	   font-weight: bold;
	   a {
	       color: #666;
	   }
	}
}


.doc-versions {
	.toggleTable {
	   position: relative;
	   background: #efefef;
	   padding: .5em .75em;
	   cursor:pointer;
	   &:after {
	      font-size: 1rem;
	      content: '';
	      display: block;
	      position: absolute;
	      right: 10px;
	      top: 1em;
	      @include sprite('if_plus');
	   }
	   &.opened {
	           &:after {
		          @include sprite('if_minus');
		       }	   
	   }
	}
}