.main-content--subhome {
    padding-bottom: 0;
	section {
	   margin: 4rem 0;
	   &.grey {
	       padding: 6rem 0 4rem;
	       background-color: #f2f2f2;
	       color: #333 !important;
	   }
	   &:first-child {
           margin-top: 0;
       }
	   &:last-child {
	       margin-bottom: 0;
	   }
	}
	h2 {
	   color:#000;
	   font-weight: bold;
	   margin-bottom: 30px;
	   font-size: 1.75em;
/* 	   &.underline { */
		   display:inline-block;
	       border-bottom: 1px solid #000;
	       padding-bottom: 3px;
/* 		} */
	}
	h3 {
	   font-weight: bold;
	   font-size: 1.5em;
	   margin-top: 0px;
	}
	h4 {
       &.underline {
           border-bottom: 1px solid #000;
           padding-bottom: 3px;
       }
	}
	.buttons {
		a.btn-md {
		  padding: 0 10px;
		  border-radius: 3px;
		  margin-bottom: 1rem;
		  font-weight: bold;
		  font-size: 13px;
		  &.btn-mb-2 {
		      margin-bottom: 2rem;
		  } 
		  &.btn-mt-2 {
              margin-top: 2rem;
          } 
	    }
	}
	
	a.btn-arrow {
	   padding: .75em 1.5em;
	   &:after {
	       display: none;
	   }
	   min-height: 120px;
	   .num {
	       font-size: 200%;
	   }
	   .txt {
	       font-size: 85%;
	   }
	}
    ul {
        list-style-type: none;
        margin-bottom: 2*$spacing;
        padding: 0;
        li {
            margin-bottom: .5*$spacing;
        }
        &.inline-list {
            font-size: 1em;
            font-weight: bold;
            li {
                display: inline-block;
                padding-right: 40px;
                position: relative;
                &:after {
                    position: absolute;
                    content: '';
                    background: #969696;
                    width: 1px;
                    height: 40px;
                    display: block;
                    right: 8px;
                    top: -8px;
                }
                &:last-child {
                    padding-right: 0;
                    &:after {
                        display: none;
                    }
                }
                a {
                    color: black;
                    &:hover {
                        color: $link-color;
                        text-decoration: none;
                    }
                }
            }
            a {
               display:block;
               position: relative;
               padding-left: 25px;
               line-height: 130%;
               margin-bottom: 8px;
               i {
                   position: absolute;
                   left: 5px;
                   top: 2px;
                   text-align: center;
                   width: 20px;
               }
           }
        }
        
        &.bordered {
            border-top: 1px solid #969696;
            padding-top: .5*$spacing;
            li {
                border-bottom: 1px solid #969696;
                padding-bottom: .5*$spacing;
            }
            
            a {
               display:block;
               position: relative;
               padding-left: 36px;
               i {
                   position: absolute;
                   left: 5px;
                   top: 2px;
                   text-align: center;
                   width: 30px;
               }
           }
        }
    }
    
    ul.numbered-list {
        li {
            border-bottom: 1px solid #ccc;
            padding: 5px 0;
            float:left;
            width: 45%;
            margin-right: 5%;
            margin-bottom: 15px;
            @media (min-width: $screen-lg-min) {
                float:left;
                width: 30%;
                margin-right: 3%;
            }
            &.full-width {
                width: 95%;
                margin-right: 0;
                float:none;
                clear:both;
                @media (min-width: $screen-lg-min) {
                    width: 96%;
                }
                
                & > a {
                    margin-bottom: 10px;
                }
                &:after {
                   content: '';
                   display: block;
                   clear: both;
                }
                li 
                {
                    float:left;
                    width: 45%;
                    margin-right: 5%;
                    border-bottom: none;
                    @media (min-width: $screen-lg-min) {
                        float:left;
                        width: 20%;
                        margin-right: 5%;
                    }
                    span.num {
                        font-size: 1.5em;
                    }
                }
            }
        }
        a {
            color:#333;
            display: block;
        }
        span.num {
            display: inline-block;
            margin-bottom: 2px;
            font-size: 2em;
            font-weight: bold;
        }
        span.txt {
            display: block;
        }
        &:after {
           content: '';
           display: block;
           clear: both;
        }
        
    }

    ul.dropdown-menu li {
        margin-top: .75rem;
        margin-bottom: .75rem;
    }
}