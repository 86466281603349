a.top {
  display: none;
  position: fixed;
  right: $spacing;
  bottom: $spacing;
  transition: transform .2s;
  z-index: 2;
  text-align: right;
  border: 0;
  width: 50px;
  height: 50px;
  @media (min-width: $screen-sm-min) {
    width: 70px;
    height: 70px;
  }
/*   @media (min-width: $screen-md-min) { */
/*     width: auto; */
/*     height: auto; */
/*   } */
  &:hover,
  &:focus {
    border: 0 !important;
  }
  img {
    width: 100%;
  }
}
