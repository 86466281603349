.id {
    display: inline-block;
    font-weight: bold;
    vertical-align: middle;
    text-transform: uppercase;
    font-size: 30px;
}
span.grid {
    font-weight: bold;
    vertical-align: middle;
    text-transform: uppercase;
    font-size: 30px;
    display: inline-block;
    @media (min-width: $screen-sm-min) {
          margin-top: -20px;
    }    
}

// alinear a la dreta al breakpoint
.text-right-bp {
    @media (min-width: $screen-sm-min) {
        text-align: right;
    }
    .btn {
        @media (min-width: $screen-sm-min) {
            margin-top: 25px;
        }   
    }
}
a.underline {
	text-decoration: underline;
}

table {
    span.pendent {
        display: block;
        margin-top: 5px;
        .fa {
            font-size: 16px;
            color: #8f8f8f;
        }
    }
}


.white-popup {
  position: relative;
  background: #FFF;
  padding: 20px;
  width:auto;
  max-width: 500px;
  margin: 20px auto;
}

.buttons {
	position: relative;
	.saveSearch {
	   padding-right: 30px;
	   position: relative;
	   &:after {
            font-family: FontAwesome;
            content: "\f0d7";
            display: block;
            position: absolute;
            right: 10px;
            bottom: 6px;
            z-index: -1;
       }
	   &.open:after {
	        font-family: FontAwesome;
		    content: "\f0de";
		    display: block;
		    position: absolute;
		    right: 10px;
		    bottom: 6px;
		    z-index: -1;
	   }
	}
	#saveSearch  {
	      position: absolute;
	      background: #FFF;
		  width:auto;
		  max-width: 500px;
		  right: 0;
          z-index: 100;
          width: 350px;
          top: 50px;
	}
	
}


.label-cancelat {
    background-color:#de4849;
}
.label-reservat,
.label-en-trasplant {
    background-color:#f49c36;
}
.label-suspes,
.label-reservat-peticio {
    background-color: #f3e36c; /*#fee424*/;
    color: #555;
}
.label-actiu {
	background-color: #3688cf;
}
.label-pendent-alta {
    background-color: #9171d8;
}

.entity-head {
    margin-bottom: 10px;
	.estat {
	   margin-bottom: 20px;
	   display: inline-block;
       @media (min-width: $screen-md-min) {
	   
	      margin-left: 2.5rem;
       }
	   .label {
	       font-size: 14px;
	   }
	}
}




.filterForm {
    margin-bottom: 3rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #ccc;
}
.sr-only-label label {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}


.sr-only-label .checkbox label {
    position: relative;
    width: auto;
    height: auto;
    overflow: auto;
    clip: initial;
    margin: 0;
}
.sr-only-label label.error {
    position: relative;
    display: block;
    width: auto;
    height: auto;
    margin: 0;
}

.data-filter, .valor-filter {
    border: 1px solid #ccc;
    padding: 0 .5em .25em;
}
/*
    .data-filter label, .valor-filter label{
        cursor: pointer;
        position: relative;
        display: block;
        
    }
        .data-filter label:after, .valor-filter label:after {
            font-family: FontAwesome;
            content: "\f0d7";
            display: block;
            position: absolute;
            right: 10px;
            top: 0px;
        }
    */    
tr.hover {
   /* whatever other hover styles you want */
   background-color: #e4e4e4 !important;
   color: #333 !important; 
}   
tr.hover-clickable {
   cursor: pointer;
   /* whatever other hover styles you want */
   background-color: #e4e4e4 !important;
   color: #333 !important; 
}   

tr.hover-clickable a {
   color: #333 !important; 
   text-decoration: none;
}    

.input-group-addon {
    padding: 6px 8px;
}

select.error:after {
    display: none;
}

.exportSearch, .moreInfo {
	&.open {
	   .caret {
		    content: "";
			border-top: 0;
			border-bottom: 4px dashed;
	   }
	}
}

.pull-right-md {
	@media (min-width: $screen-md-min) {
	   float: right;
	}
} 


.login {
	background: url(img/bg-login.jpg) no-repeat center 0 fixed; 
    background-size: cover;
    height: 100%;
/*     overflow: hidden; */
	
	h2 {
	   font-size: 20px;
       font-weight: bold;
	   margin-top: 0;
	}
	h3 {
	   font-size: 18px;
/* 	   font-weight: bold; */
	   margin-top: 0;
	}
	
	
	.text {
	   padding: 24px 16px 32px;
	   background-color: rgba(43,36,29, 0.75);
       color: #fff;
       font-size: 16px;
       
       @media (min-width: $screen-sm-min) {
          margin-top: 30px;
          padding: 42px 40px;
       }
       
       a {
         color: #fff;
         text-decoration: underline;
       }
	}
    .home-adviser {
        border-radius: 0;
       @media (min-width: $screen-sm-min) {
          margin-top: 30px;
       }
    }
	.access {
	   background-color: #c5c2bf;
	   padding: 16px 16px 32px;
	   background-color: rgba(216, 216, 216, 0.85);
	   
	   @media (min-width: $screen-sm-min) {
          margin-top: 30px;
       }
       
	   form {
	       label {
/* 	           display: none; */
	       }
		   input {
		       background-color: #d8d8d8;
		       border: 1px solid #979797;
		       font-size: 16px;
		       &::placeholder {
/* 				   text-align: center; */
/* 				   display: block; */
			   }
		   }
		   
		   button {
		      background-color: #333333;
		      color: #fff;
		      width: 100%;
		      font-weight: bold;
		      &:hover {
		          color:#ccc;
		      }
		   }
		   .buttons {
		   	margin-top: 15px;
		   }
		   
		   .lost-pass{
			   	margin-bottom: 0;
				margin-top: 15px;
				font-size: 12px;
		   }
       }
	}
}

.txtconditions {
	padding: 20px;
	border: 1px solid #ebebeb;
	color: #000;
	background-color: #f2f2f2;
	margin-bottom: 20px;
}

/*Enfosquit dels modals per evitar veure els camps de tipatge del formulari*/

.modal-backdrop.in {
    opacity: .9;
}
.questionarimedicagrupacio {
	margin-top: 1em;
	margin-bottom: 1em;
	
	.questionarimedicitem {
		border-bottom: 1px solid #eee;
		padding: 1em 0 0;
	}
}



::after, ::before {

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

}
element {

    top: -1009.42px;
    left: -13.25px;
    display: block;

}
.tipatge-popover {
    width: 350px;
    max-width: 350px;
    padding-bottom: 10px;
    
    .close {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
    }
    .popover-content {
        max-height: 300px;
        overflow-y: scroll;
        overflow-x: scroll;
    }
}

.tipatgePart--pacients {
    @media (min-width: $screen-lg-min) {
        .col-lg-2 {
            width: 7.55%;
        }
    }
}
.mt-1 {
    margin-top: 1em;
}

#sendSMSForm {
	.predefinedSMSSelection {
		max-height: 250px;
		overflow: auto;
		padding: 5px;
		margin-bottom: 10px;
		
		.message {
			background: #efefef;
			font-size: 12px;
			a {
				cursor: pointer;
				display: block;
				padding: 5px;
				&:hover {
					background: #fff;
					text-decoration: none;
				}
			}
		}
	}
}

.starburst {
    display:block;
    width:20px;
    height:20px;
    background:#f29b5b;
    -webkit-transform:rotate(-45deg);
    -moz-transform:rotate(-45deg);
    rotation:-45deg;
    position:relative;
    text-align:center;
    text-decoration:none;
    color:#000;
    font-weight:bold;
    font-family:Arial, sans-serif;
}
.starburst span {
    display:block;
    width:20px;
    height:20px;
    background:#f29b5b;
    -webkit-transform:rotate(22.5deg);
    -moz-transform:rotate(22.5deg);
    rotation:22.5deg;
}

.starburst-small { 
    width:12px;
    height:12px;
/*     -webkit-transform:rotate(18deg); */
/*     -moz-transform:rotate(18deg); */
/*     rotation:18deg; */
    span {
        width:12px;
        height:12px;
/*         -webkit-transform:rotate(18deg); */
/*         -moz-transform:rotate(18deg); */
/*         rotation:18deg; */
    }
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
.animate-flicker {
   -webkit-animation: flickerAnimation 1.2s infinite;
   -moz-animation: flickerAnimation 1.2s infinite;
   -o-animation: flickerAnimation 1.2s infinite;
    animation: flickerAnimation 1.2s infinite;
}

.mt-0 {
    margin-top: 0 !important;
}
.mt-1 {
	margin-top: 1rem !important;
}
.mt-2 {
    margin-top: 2rem !important;
}
.mt-3 {
    margin-top: 3rem !important;
}
.mt-4 {
    margin-top: 4rem !important;
}
.mt--2 {
    margin-top: -2rem !important;
}
.mb-0 {
    margin-bottom: 0 !important;
}
.mb-1 {
    margin-bottom: 1rem !important;
}
.mb-2 {
    margin-bottom: 2rem !important;
}
.mb-3 {
    margin-bottom: 3rem !important;
}
.mb-4 {
    margin-bottom: 4rem !important;
}

.ml-2 {
	margin-left: 2rem !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.mr-1 {
    margin-right: 1rem !important;
}

.mr-2 {
    margin-right: 2rem !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.ml-1 {
    margin-left: 1rem !important;
}

.ml-2 {
    margin-left: 2rem !important;
}
.panel.panel-subhome {
	border-radius: 2px;
/* 	border-color: #7e7e7e; */
	.panel-heading {
	   color: #fff;
	   background: #7e7e7e;
	   border-color: #7e7e7e;
	   font-size: 1.125em;
	}
	.panel-body {
	   font-size: 1.125em;
	   i {
	       text-align: center;
	       width: 15px;
	   }
	   a:hover {
	       text-decoration: none;
	   }
	   
	   .list-unstyled {
	       a {
	           display:block;
	           position: relative;
	           padding-left: 35px;
	           line-height: 130%;
	           margin-bottom: 8px;
	           i {
	               position: absolute;
	               left: 5px;
	               top: 2px;
	           }
	       }
	   }
	}
}



.d-block {
    display: block;
}

/*
    Visualització de dades
*/
.vd-board {
    span.btn-arrow, span.btn-arrow:hover{
        color: white;
        background: #333;
        cursor: default;
        &:after {
            display: none;
        }
    }
    
    .vd-board--panel {
        background-color: #ebebeb;
        border-radius: 8px;
        padding: 1rem 2rem;
    }
}

