.btn-list {

  font-size: 0;
  text-align: center;
  margin-left: -$base-spacing/2 !important;
  margin-right: -$base-spacing/2 !important;
  margin-bottom: 0;
  padding: 0;
  margin-top: $base-spacing * 2;

  li {
    float: left;
    width: 100%;
    padding: 0 $base-spacing/2 !important;
    margin-bottom: $base-spacing !important;

    &:before {
      display: none !important;
    }

    @media (min-width: $screen-sm-min) {
      width: 50%;
    }

    @media (min-width: $screen-lg-min) {
      width: 33.333%;
    }

  }

  a {
    font-weight: bold;
    display: table-cell;
    vertical-align: middle;
    border: 2px solid $brand-main-color;
    text-align: center;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 5px;
    color: darkergray;
    padding: 20px 40px;
    height: 128px;
    width: inherit;

    -ms-word-break: break-all;
    -ms-word-wrap: break-all;
    
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;    
    position: relative;

    &:hover,
    &:focus {
      color: white;
      background-color: $brand-main-color;
      text-decoration: none;
    }
    .new {
       position: absolute;
       top: 20px;
       right: 20px;
    }
  }
}
