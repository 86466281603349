// .btn-group.open .dropdown-toggle,
.dropdown-toggle:focus {
    outline: 5px auto -webkit-focus-ring-color;
}

.dropdown-menu.drop-left {
    right: 0;
    left: auto;
    
    .btn {
        width: 100%;
        background: transparent;
    }
}