.tools {
    text-align: left;
    padding: $spacing*.75 0;
    border-bottom: 1px solid #969696/*$gray-lighter*/;
    margin-top: -$spacing*2;
    @media (min-width: $screen-md-min) {
        text-align: center;
        /*padding: $spacing*1.5 0;*/
    }
    ul {
    	margin-bottom: 0;
    	column-count: 2;
    	font-size: 12px;
    	@media (min-width: 600px) {
	    	column-count: 3;
	    	font-size: 14px;
    	}
    	// @media (min-width: 700px) {
	    // 	column-count: 3;
	    // 	font-size: 14px;
    	// }
    	@media (min-width: $screen-md-min) {
    	    column-count: 1;
    	}
    }
    a {
    	color: black;
    	padding-top: $spacing*.5;
    	padding-bottom: $spacing*.5;
    	padding-left: 15px;
        padding-right: 15px;
    	display: block;
    	border-radius: 2px;
    	&:hover {
    		text-decoration: none;
    		border-bottom-color: inherit;
    	}
    	i {
    	   margin-right: 4px;
    	}
    }
    li {
    	display: block;
    	@media (min-width: $screen-md-min) {
    	    display: inline-block;
    	}
    	&.current {
    		a {
    			background: #11818c;
    			color: #fff;
    			font-weight: bold;
    		}
    	}
    }
}