.alert-dismissible {
    position: relative; 
    .close {
        background: transparent;
       border:none;
	   position: absolute;
	   right: 5px;
	   top: 5px; 
    }
}

.flashMessenger {
	position: fixed;
	display: block;
	z-index: 10;
	padding: 3em 4em;
	width: 70%;
	top: 4em;
	left: 50%;
	margin-left: -35%;
	font-size: 16px;
	text-align: center;
	
	.close {
	   right: 10px;
	}
}

.alert{
	&.alert-iconic {
        i {
            font-size: 4em;
            margin-bottom: 1rem;
            display:block;
            text-align: center;
        }
        text-align: center;
        border: 1px solid #bebebe;
    }
}
