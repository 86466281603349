.main-footer {
    color: #fff;
    background-color: $gray-dark;
    padding: $spacing*2 0;

    .login & {
        margin-top: 2rem;
        opacity: .85;
    }
    
    
    ul {
        margin-bottom: 0;
    }
    a {
        color: #fff;
    }
    
}

.dashboard, .login {
	.main-footer {
	    @media (min-width: $screen-sm-min) {
	        position: fixed;
	        bottom: 0;
	        width: 100%;
	        
	    }
	}

}

        
  .fixed-footer {
      .main-footer {
          position: fixed;
          bottom: 0;
          width: 100%;
      }
  }   