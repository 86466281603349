.btn {
    border-radius: 2px;
    
    &.btn-raised {
	    &:hover,
	    &:focus {
	        color: inherit;
	    }
	}
	
	&.btn-download {
	    color: #666;
	    background-color: #ccc;
	    border-color: #ccc;
	    &:hover,
        &:focus {
            color: #000;
        }
	}	
}
.btn-new {
	margin-top: 20px;
}
.btn-intro {
	margin-bottom: $spacing;
	line-height: 3.5;
	.dashboard & {
	   margin-top: 2 * $spacing;
	}
	&.btn-cordo{
	   position: relative;
	   padding-top: 3.75em;
	   &:before {
          content: '';
          display: block;
          position: absolute;
          top: 20px;
          left: 50%; 
          margin-left: -1.59375em;
          @include sprite('ico-cordon');
       }
	}
	&.btn-medula{
	   position: relative;
       padding-top: 3.75em;
       &:before {
          content: '';
          display: block;
          position: absolute;
          top: 20px;
          left: 50%; 
          margin-left: -.375em;
          @include sprite('ico-medula');
       }
    }
    &.btn-pacients{
       position: relative;
       padding-top: 3.75em;
       &:before {
          content: '';
          display: block;
          position: absolute;
          top: 20px;
          left: 50%; 
          margin-left: -1.95em;
          @include sprite('ico-pacients');
       }
    }
    &.btn-cerques{
       position: relative;
       padding-top: 3.75em;
       &:before {
          content: '';
          display: block;
          position: absolute;
          top: 20px;
          left: 50%; 
          margin-left: -1.5em;
          @include sprite('ico-lupa');
       }
    }
    &.btn-contacte{
       position: relative;
       padding-top: 3.75em;
       &:before {
          content: '';
          display: block;
          position: absolute;
          top: 20px;
          left: 50%; 
          margin-left: -1.5em;
          @include sprite('ico-directori');
       }
    }
    &.btn-docs{
       position: relative;
       padding-top: 3.75em;
       &:before {
          content: '';
          display: block;
          position: absolute;
          top: 20px;
          left: 50%; 
          margin-left: -1.125em;
          @include sprite('ico-doc');
       }
       .new {
	        position: absolute;
	        top: 20px;
	        right: 20px;
	        .starburst { 
                background: #f29b5b;
                span {
                    background: #f29b5b;
                }
            }
       }
    }
    
    &.btn-wiki{
       position: relative;
       padding-top: 3.75em;
       &:before {
          content: '';
          display: block;
          position: absolute;
          top: 20px;
          left: 50%; 
          margin-left: -1.125em;
          @include sprite('ico-doc');
       }
    }
    
    &.btn-coordinacioautonomica{
       position: relative;
       padding-top: 3.75em;
       &:before {
          content: '';
          display: block;
          position: absolute;
          top: 12px;
          left: 50%; 
          margin-left: -3.125em;
          @include sprite('ico-coordinacio_autonomica');
       }
    }
}
.btn-arrow {
	color: $text-color;
	margin-bottom: $spacing;
	background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(229, 229, 229, 0.5)), linear-gradient(#ffffff, #ffffff);
	border: solid 1px #979797;
	text-align: left;
	padding: 1em 2em;
	white-space: inherit;
	position: relative;
	
	
	.num {
		font-size: 250%;
		font-weight: 800;
		display: block;
	}
	span {
		vertical-align: middle;
		display: inline-block;
	}
	
	i {display: none;}
	&::after {
	    content: '';
	    background: url(img/arrow.svg) no-repeat 0 0;
	    position: absolute;
	    bottom: 1.25rem;
	    right: 2rem;
	    width: 25px;
	    height: 25px;
	    opacity: .4;
	    transition: all .3s ease-out;
	}
	&:hover {
		color: $text-color;
		&::after {
	        opacity: 1;
	    }
	}
	
	&.btn-arrow-number {
	    min-height: 9em;
	   
		&.btn-arrow-longtext {
	       
	       .txt {
	           margin-top: 0;
	           max-width: 90%;
	       }
	    }
	}
	
	
}

.btn-default--primary {
	background-color: $link-color;
	border-color: $link-color;
	color: #fff;
}
.btn-default--lighter {
	background-color: #7e7e7e;
	border-color: #7e7e7e;
}

.btn-default--ultralighter {
    background-color: #ebebeb;
    color: #666;
    border-color: #ebebeb;
}

.btn-default--bordered {
    color: $text-color;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(229, 229, 229, 0.5)), linear-gradient(#ffffff, #ffffff);
    border: solid 1px #979797;
    &:hover {
        color: black;
    }
}

.dl-buttons {
	margin: 10px 0;
	a {
	   display: inline-block;
	   margin-bottom: 5px;
	   margin-right: 10px;
	} 
}