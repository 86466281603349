.contacte {
	hr {
	   border: none;
	}
	h2 {
		border-bottom: 1px solid #eee;
		padding-bottom: .5rem;
		margin-top: 3rem;
		&:first-child{
		  margin-top: 1rem;
		}
	}
    .vcard {
        
        margin-bottom: 2.5rem;
        background: #eee;
        padding: 2rem 2.5rem;
        
        .fn{
            display:inline-block;
            font-weight: bold;
            margin-bottom:.25rem;
            font-size: 1.1425em;
        }
        .carrec {
            display:block;
            margin-bottom:.5rem;
        }
        .contact_wrapper {
            margin-top: .75rem;
            p {
                margin-bottom: .25rem;
                &.email_wrapper {
                    span {display: none;}
                }
            }
        }
        
    }
    .vcard-container {
        
        margin-bottom: 2.5rem;
        background: #eee;
        p.carrec {
            padding: 2rem 2.5rem 0;
            margin-bottom: 12px;
        }
        .vcard {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
