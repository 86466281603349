
.wiki_item {
	padding: 20px 0;
	margin-bottom: 10px;
	border-bottom: 3px solid #ccc;
	p {
	   font-size: 15px
	}
	strong {
	   font-weight: normal;
	   font-size: 17px;
	   display: inline-block;
	   border-bottom: 1px solid #333;
	   padding-bottom: 2px;
	   margin-bottom: 5px;
	   color: #333;
	}
	span {
	   display:block;
	}
}

.wiki_abrev {
	div {
	   margin-bottom: 1rem;
	   border-bottom: 1px solid #ebebeb;
	   min-height: 1%;
	   &:after {
	       clear: both;
	       content: ' ';
	       display: block;
	       padding-bottom: 1rem;
	   }
	}
	dt {
	   float: left;
	   width: 35%;
	}
	dd {
	   float: right;
       width: 65%;
	}
}