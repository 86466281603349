.avis {
    color: #ffffff;
    font-family: 'Open Sans';
    
    &.fade-out {
        display: none;
    }
}
    .avis.avis-dark {
        background-color: #333333;
        padding: 3rem 1rem 1.5rem;
    }
    .avis.avis-gradient {
        background-image: linear-gradient(to right, #f56b02, #00c0d3 50%, #6dc34b);
        padding: 0 1rem 1.5rem;
    }
    .avis + .avis.avis-gradient {
        padding-top: 3rem;
    }
    .avis.avis-image {
        text-align: center;
        padding-bottom: 2.5rem;
    }
        .avis.avis-image .avis--content .image img{
            max-width: 100%;
        }
    .avis.avis-imagetext .avis--content {
        display: grid;
        grid-template-columns: 1fr 2fr;
    }
        .avis.avis-imagetext .avis--content .image {
            text-align: center;
            padding-right: 3rem;
        }
            .avis.avis-imagetext .avis--content .image img{
                max-width: 100%;
            }
    .avis .avis--wrapper {
        position: relative;
        max-width: 600px;
        margin: 0 auto;
        padding-right: 70px;
    }
        .avis .avis--wrapper .close {
            position: absolute;
            right: 0px;
            top: -10px;
            width: 32px;
            height: 32px;
            opacity: 0.8;
        }
            .avis .avis--wrapper .close:hover {
                opacity: 1;
            }
            .avis .avis--wrapper .close:before, .avis .avis--wrapper .close:after {
                position: absolute;
                left: 15px;
                content: ' ';
                height: 33px;
                width: 2px;
                background-color: #fff;
            }
            .avis .avis--wrapper .close:before {
                transform: rotate(45deg);
            }
            .avis .avis--wrapper .close:after {
                transform: rotate(-45deg);
            }
            .avis .avis--wrapper p {
                font-size: 16px;
                font-weight: 200;
                margin-bottom: 15px;
            }
            .avis .avis--wrapper p:last-child{
                margin-bottom: 0;
            }
            .avis .avis--wrapper strong {
              font-weight: 600;
            }
            .avis .avis--wrapper a {
              color:#fff;
              text-decoration: underline;
            }
                .avis .avis--wrapper a:hover {
                  text-decoration: none;
                }
                .avis .avis--wrapper a.btn {
                  border: 2px solid #FFFFFF;
                  border-radius: 4px;
                  text-decoration: none;
                  color: #fff;
                  padding-left: 2.5rem;
                  padding-right: 2.5rem;
                  margin-right: 10px;
                  left-right: 10px;
                  margin-bottom: 1.5rem;
                }
                    .avis .avis--wrapper a.btn:hover {
                        background: rgba(255,255,255, .15);
                    }
            .avis .avis--wrapper .text-center {
              text-align: center;
            }
            .avis .avis--wrapper .bigger {
              font-size: 24px;
              margin-bottom: 18px;
            }
            .avis .avis--wrapper .big {
              font-size: 20px;
              margin-bottom: 18px;
            }

            .avis .avis--wrapper .small {
              font-size: 14px;
            }
            .avis .avis--wrapper .smaller {
              font-size: 11px;
            }