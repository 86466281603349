main {
	padding-top: $spacing;
    padding-bottom: $spacing*2;
    
/*     @media (min-width: $screen-sm-min) { */
/*       padding-top: $spacing*2; */
/*     } */
}
.container-fluid {
	max-width: 90em;
}

// reducció espai entre columnes
.row-narrow {
	@include make-row($gutter: calc($grid-gutter-width/2));
	[class*=col-] {
		padding-left: calc($grid-gutter-width/6);
		padding-right: calc($grid-gutter-width/6);
	}
}
// ampliació espai entre columnes
.row-separated {
    @include make-row($gutter: $grid-gutter-width*2);
    [class*=col-] {
        padding-left: $grid-gutter-width;
        padding-right: $grid-gutter-width;
    }
}
// .col-narrow-1 {
// 	@include make-xs-column(1, $gutter: $grid-gutter-width/2);
// }
// .col-narrow-2 {
// 	@include make-xs-column(2, $gutter: $grid-gutter-width/2);
// }
// .col-narrow-3 {
// 	@include make-xs-column(3, $gutter: $grid-gutter-width/2);
// }
// .col-narrow-4 {
// 	@include make-xs-column(4, $gutter: $grid-gutter-width/2);
// }
// .col-narrow-5 {
// 	@include make-xs-column(5, $gutter: $grid-gutter-width/2);
// }
// .col-narrow-6 {
// 	@include make-xs-column(6, $gutter: $grid-gutter-width/2);
// }
// .col-narrow-7 {
// 	@include make-xs-column(7, $gutter: $grid-gutter-width/2);
// }
// .col-narrow-8 {
// 	@include make-xs-column(8, $gutter: $grid-gutter-width/2);
// }
// .col-narrow-9 {
// 	@include make-xs-column(9, $gutter: $grid-gutter-width/2);
// }
// .col-narrow-10 {
// 	@include make-xs-column(10, $gutter: $grid-gutter-width/2);
// }
// .col-narrow-11 {
// 	@include make-xs-column(11, $gutter: $grid-gutter-width/2);
// }
// .col-narrow-12 {
// 	@include make-xs-column(12, $gutter: $grid-gutter-width/2);
// }

// per centrar columnes
[class*=col-].center-block {
	float: none;
}